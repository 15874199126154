import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Row, Form, Label, Input, FormGroup, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, FormText, Spinner } from "reactstrap";
import { ClassService } from '../services/ClassService.ts';
import { KartService } from '../services/kartService.ts';
import { AuthService } from "../services/authService.ts";
import { auth } from '../firebaseConfig';
import { SubscriptionsService } from '../services/SubscriptionService.ts';

import CourseCard from "./CourseCard";
export default function CartScreen() {

  const [ClassList, setClassList] = useState([])
  const [loading, setloading] = useState(true)
  const classService = new ClassService()
  const kartService = new KartService()
  const subService = new SubscriptionsService()

  useEffect(() => {
    loadClasses()

  }, [])

  //Buy Course
  const onClickHandler = async (course) => {
    try {
      //Get Key from db
      const creds = await kartService.getMetaData("razorpay")
      const key = creds["key"]
      console.log(key)
      const uid = auth.currentUser.uid
      const classID = course["classID"]

      //Check with course is already bought
      let res = await subService.checkIfSubExists(uid + "_" + course["classID"])
      console.log(res)

      //Trigger rezorpay
      const options = {
        key: key,
        amount: course.price * 100,
        currency: "INR",
        name: course.name,
        description: "Transition Through web",
        handler: async(response) =>{
          //On Payment Success
          const paymentID = response.razorpay_payment_id
          const baughtDate = new Date()
          const price = course["price"]
          const subscriptionID = uid + "_" + course["classID"]
          const endDate = new Date()
          endDate.setFullYear(baughtDate.getFullYear() + 1)
          const subscription = {
            "paymentID": paymentID,
            "userID": uid,
            "baughtDate": baughtDate,
            "price": price,
            "subscriptionID": subscriptionID,
            "classID": classID,
            "endDate": endDate
          }
          console.log(subscription)
          await subService.addNewSubscription(subscription)
          await kartService.removeCourse(uid,classID)
          await loadClasses()
          //PopUp
          var x = document.getElementById("snackbar");
          // Add the "show" class to DIV
          x.className = "show";
          // After 3 seconds, remove the show class from DIV
          setTimeout(function () { x.className = x.className.replace("show", ""); }, 5000);

        }
      };
      console.log(options)
      const rzp1 = new window.Razorpay(options);
      rzp1.open()

    } catch (error) {
      alert(error.message)
    }
  }


  const loadClasses = async () => {

    const uid = localStorage.getItem("uid")
    let cart = await kartService.getCartByUserID(uid)
    let tempList = []
    //Get Courses
    for (const classID in cart["courses"]) {
      const course = await classService.getClassByID(cart["courses"][classID])
      console.log(course)
      tempList.push(course)
    }
    setClassList(tempList)
    setloading(false)
  }
  const logOutUser = async () => {
    try {
      const res = await AuthService.authenticateWithGoogle()
      window.location = "/"
    } catch (error) {
      alert(error.message)

    }
  }
  return (
    <div>
      {/* <Modal isOpen={true} size="lg" centered={true}> */}
      <div style={{ display: "flex", "flexDirection": "row", "justifyContent": "space-between", "width": "100vw", "padding": "1vw", "backgroundColor": "#E5E1D6" }}>
        <h2>Purchase  Courses</h2>
        <Button onClick={() => { logOutUser() }}>Logout</Button>
      </div>
      <h3 style={{ "margin": "20px" }}>Your Cart</h3>
      <ModalBody style={{
        display: "grid", "gridTemplateColumns": "auto auto auto",
        "justifyContent": "center",
        "alignContent": "center"
      }}>
        {loading ? <Spinner> "Loading..." </Spinner> :ClassList.length<1 ?<h5 style={{ "margin": "20px","color":"gray" }}>Your cart is empty</h5>:ClassList.map((cl) => { return <CourseCard course={cl} onClickHandler={() => { onClickHandler(cl) }} /> })}
      </ModalBody>
      {/* </Modal> */}
    </div>
  )
}
