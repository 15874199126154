import React, { useEffect, useState } from 'react'
import { Button, Modal, ModalBody, ModalHeader, Row, Form, Label, Input, FormGroup, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, FormText } from "reactstrap";

export default function CourseCard(props) {
    const course = props.course
    const onClickHandler = props.onClickHandler


    const buyCourse = async (course) => {
        
    }

    return (
        <div>
            <div id='snackbar'>subscription ready added</div>
            <Card
                color="light"
                style={{
                    width: '20vw',
                    marginBottom: "20px",
                    marginLeft: "20px",
                    marginRight: "20px",
                    "userSelect": "none"
                }}
            >
                <img
                    alt="Sample"
                    src={course["thumbnailUrl"]}
                />
                <CardBody>
                    <CardTitle tag="h5">
                        {course["name"]}
                    </CardTitle>
                    <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                    >
                        ₹{course["price"]} /-
                    </CardSubtitle>
                    <CardText>
                        {course["subjectIDs"].length} Subjects
                    </CardText>
                    <Button onClick={onClickHandler}>
                        Buy
                    </Button>
                </CardBody>
            </Card>
        </div>)

}
