export interface Course {
    boardID:      string;
    classID:      string;
    creationDate: Date;
    name:         string;
    price:        number;
    rating:       number;
    searchTags:   string[];
    subjectIDs:   string[];
    thumbnailURL: string;
}

// Converts JSON strings to/from your types
export class Convert {
    public static toClass(json: string): Course {
        return JSON.parse(json);
    }

    public static classToJson(value: Course): string {
        return JSON.stringify(value);
    }
}
