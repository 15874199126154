import firebase from "firebase/compat";
import { auth, db } from "../firebaseConfig";
import { Course } from "../models/Course";
import { Convert } from "../models/Subject";
import { Subject } from "../models/Subject";
import { User } from "../models/User";
import {  signOut } from "firebase/auth";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


export class AuthService {
    
    public static async authenticateWithGoogle(){
        console.log("Google Sign in")
        const provider = new GoogleAuthProvider();
        let result = await signInWithPopup(auth,provider);
        const credential = GoogleAuthProvider.credentialFromResult(result);
        console.log(credential);
        const token = credential!.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("user");
        const uid = user.uid;

        //get unskooler user by UID,
        const userDb = db.collection("users");
        const doc = await userDb.doc(uid).get()
        if (doc.exists) {
            
            return {"message": "User Flound", "success":true, "object": doc.data()}
        } else {
            auth.currentUser?.delete()
            auth.signOut();
            return {"message": "User not found", "success":false}
        }
    }
    
    public static async LogOutUser(){
        try {
            let res = await signOut(auth)
            console.log(res)
            return {ok:true,"message":"User Signed Out"}
    
        } catch (error) {
            throw error
        }
    }



}
