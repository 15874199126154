import { Button, Modal, ModalBody, ModalHeader, Row, Form, Label, Input, FormGroup, Card, CardImg, CardBody, CardTitle, CardSubtitle, CardText, FormText } from "reactstrap";
import { useState } from 'react';
import { AuthService } from "./services/authService.ts";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginComponent from "./Compoenets/LoginComponent";
import CartScreen from "./Compoenets/CartScreen";
import "./App.css"

function App() {
  
  return (
    <div className="App">
      <Routes>
        <Route path="/" element= {<LoginComponent/>}/>
        <Route path="/cart" element= {<CartScreen/>}/>
      </Routes>
    </div>
  );
}

export default App;
