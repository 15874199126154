import firebase from "firebase/compat";
import { db } from "../firebaseConfig";
import { Convert } from "../models/Subscriptions";
import { Subscriptions } from "../models/Subscriptions";


export class SubscriptionsService{
    subscriptionDB = db.collection("subscriptions");

    public async addNewSubscription(subscriptions: Subscriptions) {
        try {
            var doc  =  await this.subscriptionDB.doc(subscriptions.subscriptionID).set(subscriptions);
            return { "success": true, "message": "new subscriptions added" }
        } catch (error) {
            return { "success": false, "message": "Unable to add class: " + error.message }
        }
    }

    public async checkIfSubExists(subscriptionID: string) {
            var doc  =  await this.subscriptionDB.doc(subscriptionID).get();
            console.log(doc.data())
            console.log(doc.exists)
            if (doc.exists) {
                throw {message:"Subscription Already exist"}
            }
            return { "success": true, "message": "new subscriptions added" }
    }
   
}
