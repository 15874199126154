import React from 'react'
import { useState } from 'react';
import { AuthService } from "../services/authService.ts";
import { Button, Modal, ModalBody, ModalHeader, Row, Form, Label, Input, FormGroup } from "reactstrap";

export default function LoginComponent() {
    const loginGoogleUser = async ()=>{
        try {
            const res = await AuthService.authenticateWithGoogle()
            console.log(res["object"]["uid"])
            localStorage.setItem("uid",res["object"]["uid"])
            window.location = "/cart"
        } catch (error) {
            
        }
    }
    const [modal, setmodal] = useState(true)
    return (
        <div><Modal size="s" isOpen={modal} centered={true}>
            <ModalHeader>Unskooler Login</ModalHeader>
            <ModalBody >
                <div style={{ "display": "flex", "flexDirection": "row" }}>
                    <Button color="primary" outline style={{ margin: "20px", fontSize: "15px" }} onClick={() => loginGoogleUser()}>Login with Google</Button>
                    <Button color="primary" outline style={{ margin: "20px", fontSize: "15px" }}>Login with Apple</Button>
                </div>
                <Form>
                    <FormGroup>
                        <Label for="exampleEmail">
                            Email
                        </Label>
                        <Input
                            id="exampleEmail"
                            name="email"
                            placeholder="Enter Email"
                            type="email"
                        />
                        <Label for="exampleEmail">
                            Password
                        </Label>
                        <Input
                            id="exampleEmail"
                            name="email"
                            placeholder="Enter Password"
                            type="password"
                        />
                    </FormGroup>
                </Form>
                <Button color="primary">Submit</Button>
            </ModalBody>
        </Modal></div>
    )
}
