import { db } from "../firebaseConfig";
import { Course } from "../models/Course.ts";
import { Convert } from "../models/Course.ts";


export class ClassService {
    classDB = db.collection("classes");

    public async getClassByBoardID(boardID: String) {
        var classes: Array<Course> = [];
        //console.log("Retrivig Classes from Db for: " + boardID);
        var snapschots = await this.classDB.where("boardID", "==", boardID).get();
        //console.log(snapschots.docs);
        snapschots.docs.forEach(element => {
            //console.log(element.data());
            classes.push(Convert.toClass(JSON.stringify(element.data())))
        });
        return classes;
    }
    public async getClassByID(classID: string) {
        console.log(classID)
        //console.log("Retrivig Classes from Db for: " + boardID);
        var classDoc = await this.classDB.doc(classID).get()
        console.log(classDoc);
        return classDoc.data();
    }

    public async getAllClasese(){
        let classes: Array<Course> =[];
        let snapschots = await this.classDB.get();
        //console.log(snapschots.docs);
        snapschots.docs.forEach(element => {
            //console.log(element.data());
            classes.push(Convert.toClass(JSON.stringify(element.data())))
        });
        return classes;
    }

    public async addNewClass(grade: Course) {
        try {
            var doc  =  await this.classDB.doc(grade.classID).set(grade);
            return { "success": true, "message": "class added" }
        } catch (error) {
            return { "success": false, "message": "Unable to add class: " + error }
        }
    }
    public async updateClass(classID: string, graded: Course){
        try {
            await this.classDB.doc(classID).update(graded)
            return { "success": true, "message": "class Updated" }
        } catch (error) {
            return { "success": false, "message": "Unable to add class: " + error }
        }
    }
    public async deleteClass(classID: string){
        try {
            await this.classDB.doc(classID).delete()
            return { "success": true, "message": "Class deleted" }
        } catch (error) {
            return { "success": false, "message": "Unable to delete class: " + error }
        }

    }

}
