import { db } from "../firebaseConfig";
import { Convert } from "../models/Module";
import { Module } from "../models/Module";
import { doc } from "firebase/firestore";
import firebase from "firebase/compat/app";
export class KartService{
    kartDB = db.collection("kart");
    
    public async getCartByUserID(uid: string){
        const kart = await this.kartDB.doc(uid).get()
        if (!kart.exists) {
            throw {"message":"no cart found"}
        }
        return kart.data()
    }
    public async getMetaData(docID :string){
        const Mdb = db.collection("metadeta");
        const kart = await Mdb.doc(docID).get()
        if (!kart.exists) {
            throw {"message":"no doc found found"}
        }
        return kart.data()
    }
    public async removeCourse(uid :string,courseID: string){
        try {
            let res = await this.kartDB.doc(uid).update({ ["courses"]: firebase.firestore.FieldValue.arrayRemove(courseID) })
            return res
        } catch (error) {
            throw error
        }
    }
    
}
